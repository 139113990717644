import React from 'react';
import Datalist from './citylist';
import { cp_iptxt, cp_iptxt2, cp_iptxt3, ef, ef2 } from './citytitle10.module.css';
import {Link} from 'gatsby';
import CesiumContainer from "../../pages/cesiumContainer"
import Refresh from "../../components/refresh"

function useStickyState(defaultValue, key) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = typeof window !== 'undefined' && window.localStorage.getItem(key);
    return stickyValue !== null
      ? JSON.parse(stickyValue)
      : defaultValue;
  });
  React.useEffect(() => {
    typeof window !== 'undefined' && window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}

const refreshPage = ()=>{
  window.location.reload();
}


const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value);
    }
    }, [value]);
 
  return [value, setValue];
};

const App = (props) => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );

  const onChange = event => setValue(event.target.value);

  const kotei = props.kotei;
  const no = props.no;
console.log(kotei);


  return (
  <div>
    <div style={{display:"flex", position:"absolute", backgroundColor:"blue",color:"white",fontSize:"x-large"}}>
      <div
       className={cp_iptxt}
      >
        <div className={ef}>
        {kotei}
        </div>
          {/* <input
            value={tenkei_city}
            className={ef}
            type="text"
            placeholder="市町村名を入力、選択肢から選ぶ"
            onChange={onChange} 
            autoComplete="on"
            list="keywords"
          /> */}
          {/* <label>市町村名を入力 ＞選択</label> */}
          {/* <span className={focus_line}></span> */}
          {/* <Datalist /> */}
      </div>

      {/* <div className={cp_iptxt2}>
        <div className={ef2}>

        <a href={"/"+no+"/a2/#t"}>
        <div>
          地域を選ぶ
        </div>
      </a>
        </div>
      </div> */}

      {/* <div className={cp_iptxt3}>
          ⇒
      </div> */}

      {/* <div style={{width:"25vw"}}>

      </div> */}
      {/* <div className={cp_iptxt2}>
        <div style={{
          color: "#fff",
          backgroundColor: "white",
          background: "white",
          borderRadius: "2px 2px 2px 2px",
          padding: "5px 5px 5px 5px",
          textAlign: "center",
          cursor: "pointer",
          margin:"10px 0 10px -20px", 
          width:"100%",
          border:"inset 2px #0000ff",
          }}>
          <a href={props.kml_kmz} download>Google Earthで表示</a>
        </div>
      </div> */}

    </div>
  </div>
  );
};

export default App;